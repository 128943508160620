import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaEnvelope, FaLinkedin, FaGithub, FaPaperPlane, FaReact, FaHeart } from 'react-icons/fa';
import { SiTailwindcss } from 'react-icons/si';
import './ContactFooter.css';

const ContactFooter = ({ darkMode }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', { name, email, message });
  };

  return (
    <section id="contact" className={`contact-footer ${darkMode ? 'dark' : ''}`}>
      <div className="contact-container">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Let's Connect
        </motion.h2>
        <div className="contact-content">
          <motion.form
            className="contact-form"
            onSubmit={handleSubmit}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <input
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <textarea
              placeholder="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
            <button type="submit">
              Send Message <FaPaperPlane />
            </button>
          </motion.form>
          <motion.div
            className="contact-info"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h3>Let's Create Something Amazing</h3>
            <p><FaEnvelope /> varungolusupudi@gmail.com</p>
            <p>I'm always open to new opportunities and collaborations. Whether you have a project in mind or just want to chat, feel free to reach out!</p>
            <div className="social-links">
              <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <FaLinkedin />
              </a>
              <a href="https://github.com/yourusername" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
                <FaGithub />
              </a>
            </div>
          </motion.div>
        </div>
      </div>
      <footer className="footer">
        <div className="footer-content">
          <div className="built-with">
            <p>Built with <FaHeart className="heart-icon" /> using</p>
            <div className="tech-icons">
              <FaReact className="tech-icon react-icon" title="React" />
              <SiTailwindcss className="tech-icon tailwind-icon" title="Tailwind CSS" />
            </div>
          </div>
          <div className="copyright">
            <p>&copy; 2024 Varun Golusupudi. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default ContactFooter;