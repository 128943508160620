import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaIceCream, FaMoon, FaSun } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

const emojis = ["😃", "🎉", "✨", "🚀", "🌟", "❤️", "😎", "👍", "😂", "🍦", "🥳", "🔥", "💪", "👏"];

const getRandomPosition = () => ({
    left: `${Math.random() * 100}vw`
});

const emojiVariants = {
    initial: { opacity: 0, y: '100vh', scale: 0 },
    animate: { opacity: 1, y: '-50vh', scale: 1, transition: { duration: 2 } },
    burst: { opacity: 0, scale: 1.5, transition: { duration: 0.3 } }
};

const menuVariants = {
    closed: { opacity: 0, scale: 0.95 },
    open: { opacity: 1, scale: 1 }
};

const underlineVariants = {
    initial: { width: 0 },
    hover: { width: '100%' },
};

function Navbar({ darkMode, toggleDarkMode }) {
    const [isOpen, setIsOpen] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [emojiQueue, setEmojiQueue] = useState([]);

    useEffect(() => {
        if (hoveredItem !== null) {
            setEmojiQueue([0]);
        }
    }, [hoveredItem]);

    const handleMouseEnter = (index) => {
        setHoveredItem(index);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
        setEmojiQueue([]);
    };

    const handleAnimationComplete = () => {
        setEmojiQueue((prevQueue) => {
            const [first, ...rest] = prevQueue;
            if (first !== undefined) {
                const nextIndex = first + 1;
                if (nextIndex < emojis.length) {
                    return [...rest, nextIndex];
                }
            }
            return rest;
        });
    };

    return (
        <>
            <motion.nav 
                className={`fixed top-0 right-0 m-4 z-50 ${darkMode ? 'dark' : ''}`}
                initial={false}
                animate={isOpen ? "open" : "closed"}
            >
                <motion.button 
                    onClick={() => setIsOpen(!isOpen)} 
                    className="p-2 rounded-full border-2 border-black hover:bg-gray-300 transition-colors duration-200"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                >
                    {isOpen ? 
                        <IoClose className="text-2xl" /> :
                        <FaIceCream className="text-2xl" />
                    }
                </motion.button>
                <motion.button 
                    onClick={toggleDarkMode} 
                    className="p-2 rounded-full border-2 border-black hover:bg-gray-300 transition-colors duration-200 ml-2"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                >
                    {darkMode ? <FaSun className="text-2xl" /> : <FaMoon className="text-2xl" />}
                </motion.button>
            </motion.nav>
            
            <AnimatePresence>
                {isOpen && (
                    <motion.div 
                        className={`fixed inset-0 ${darkMode ? 'bg-gray-800' : 'bg-warm-gray-100'} flex items-center justify-center z-40`}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={menuVariants}
                        transition={{ duration: 0.3 }}
                    >
                        <ul className="text-center">
                            {['Home', 'About', 'Experience', 'Works', 'Contact'].map((item, index) => (
                                <motion.li 
                                    key={item} 
                                    className="my-6 relative"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: index * 0.1 }}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <motion.a 
                                        href={`#${item.toLowerCase()}`} 
                                        className={`font-serif text-4xl font-bold ${darkMode ? 'text-gray-200' : 'text-warm-gray-800'} hover:${darkMode ? 'text-gray-400' : 'text-warm-gray-600'} transition-colors duration-200 relative`}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        {item}
                                        <motion.div
                                            className="absolute left-0 bottom-0 h-1"
                                            style={{ backgroundColor: darkMode ? '#ff79c6' : '#6272a4' }}
                                            initial="initial"
                                            animate={hoveredItem === index ? "hover" : "initial"}
                                            variants={underlineVariants}
                                            transition={{ duration: 0.3 }}
                                        />
                                    </motion.a>
                                    <AnimatePresence>
                                        {hoveredItem === index && emojiQueue.map((i) => (
                                            <motion.div
                                                key={i}
                                                className="fixed bottom-0 text-3xl"
                                                style={getRandomPosition()}
                                                initial="initial"
                                                animate="animate"
                                                exit="burst"
                                                variants={emojiVariants}
                                                transition={{ duration: 2, times: [0, 0.8, 1] }}
                                                onAnimationComplete={handleAnimationComplete}
                                            >
                                                <div className="bg-white p-2 rounded-full shadow-lg">
                                                    {emojis[i]}
                                                </div>
                                            </motion.div>
                                        ))}
                                    </AnimatePresence>
                                </motion.li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
}

export default Navbar;
