import './App.css';
import './styles.css';
import './index.css';
import Landing from './Landing';
import AboutMe from './About';
import WorkExperience from './WorkExperience';
import Education from './Education';
import Works from './Works';
import ContactFooter from './ContactFooter';

function App() {
  return (
    <div className="App">
      <Landing/>
      <AboutMe/>
      <Education/>
      <WorkExperience/>
      <Works/>
      <ContactFooter/>
    </div>
  );
}

export default App;
