import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaGithub, FaGlobe, FaCode, FaTrophy, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './Works.css';

const ProjectCard = ({ title, description, achievement, github, website, darkMode }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div 
      className={`project-card ${darkMode ? 'dark' : ''}`}
      whileHover={{ y: -5 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <div className="card-content">
        <h3>{title}</h3>
        {achievement && (
          <div className="achievement">
            <FaTrophy /> {achievement}
          </div>
        )}
        <motion.div 
          className="description"
          animate={{ height: isExpanded ? 'auto' : '100px' }}
        >
          <p>{description}</p>
        </motion.div>
        <button className="expand-btn" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      <div className="project-links">
        {github && (
          <a href={github} target="_blank" rel="noopener noreferrer" className="icon-link github">
            <FaGithub />
          </a>
        )}
        {website && (
          <a href={website} target="_blank" rel="noopener noreferrer" className="icon-link website">
            <FaGlobe />
          </a>
        )}
      </div>
    </motion.div>
  );
};

const Works = ({ darkMode }) => {
  const projects = [
    {
      title: "AequitasAI: Democratizing Legal Access",
      description: "AequitasAI is driven by the belief that justice should be accessible to everyone, regardless of socioeconomic status. We're bridging the gap between complex legal systems and the average person, while also aiming to revitalize the startup ecosystem by simplifying legal processes. Our custom AI solutions demystify environmental regulations and legal complexities, potentially contributing to economic dynamism.",
      achievement: "Top 20 among 300 teams at UC Berkeley AI Hackathon",
      github: "https://github.com/yourusername/AequitasAI",
      website: "https://aequitasai.com"
    },
    {
      title: "SlugNutrition App",
      description: "Mobile app helping UCSC students make informed dietary choices on campus.",
      github: "https://github.com/yourusername/SlugNutritionApp",
      website: "https://slugnutrition.app"
    },
    {
      title: "SlugNutrition Website",
      description: "Web platform complementing the SlugNutrition app with additional resources and information.",
      github: "https://github.com/yourusername/SlugNutritionWeb",
      website: "https://slugnutrition.com"
    }
  ];

  return (
    <section id="works" className={`works-section ${darkMode ? 'dark' : ''}`}>
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <FaCode /> My Works
      </motion.h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} darkMode={darkMode} />
        ))}
      </div>
    </section>
  );
};

export default Works;