import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaGraduationCap, FaCalendar, FaMapMarkerAlt, FaUsers, FaStar, FaChevronDown, FaChevronUp, FaTrophy } from 'react-icons/fa';
import './Education.css';

const AwardCard = ({ title, issuer, date, description }) => (
  <div className="award-card">
    <FaTrophy className="award-icon" />
    <div className="award-content">
      <h4>{title}</h4>
      <p>{issuer} · {date}</p>
      <p>{description}</p>
    </div>
  </div>
);

const EducationCard = ({ school, degree, duration, location, activities, grade, awards, darkMode }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div 
      className={`education-card ${darkMode ? 'dark' : ''}`}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3>{school}</h3>
      <h4>{degree}</h4>
      <p className="duration-location">
        <FaCalendar /> {duration} <FaMapMarkerAlt /> {location}
      </p>
      {activities && (
        <p className="activities">
          <FaUsers /> Activities: {activities}
        </p>
      )}
      {grade && (
        <p className="grade">
          <FaStar /> Grade: {grade}
        </p>
      )}
      {awards && awards.length > 0 && (
        <div className="awards-section">
          <button className="toggle-awards" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Hide Awards' : 'Show Awards'} {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </button>
          <AnimatePresence>
            {isExpanded && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                {awards.map((award, index) => (
                  <AwardCard key={index} {...award} />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </motion.div>
  );
};

const Education = ({ darkMode }) => {
  const educations = [
    {
      school: "Baskin Engineering at UCSC",
      degree: "Bachelor of Science - BS, Computer Science",
      duration: "Sep 2021 - Jun 2025",
      location: "Santa Cruz, California",
      activities: "Alpha Iota Omicron, Competitive Programming club, UCSC Tennis club, Indian Student Association"
    },
    {
      school: "Jubilee Hills Public School",
      degree: "12th, Business/Commerce",
      duration: "Jun 2019 - Jun 2021",
      location: "Hyderabad, India",
      grade: "9.25 CGPA",
      awards: [
        {
          title: "Best Sports Student Award for 2018",
          issuer: "Jubilee Hills Public School",
          date: "Nov 2019",
          description: "Won the Award for extraordinary sports achievements and representing the school at the national level for two years straight."
        }
      ]
    },
    {
      school: "Jubilee Hills Public School",
      degree: "10th",
      duration: "2007 - May 2019",
      location: "Hyderabad, India",
      awards: [
        {
          title: "Represented High School at National Level U-17",
          issuer: "School Games Federation of India (SGFI)",
          date: "Jan 2018",
          description: "Won Gold medals for Jubilee Hills Public School at District level and State Level and represented the school at National Level."
        },
        {
          title: "Telangana State U14 Tennis Champion",
          issuer: "TSTA",
          date: "Feb 2017",
          description: "Awarded U-14 Tennis Champion by Telangana State Tennis Association(TSTA) for outstanding performance in Tennis at the state level"
        },
        {
          title: "Represented High School at National Level for U-14",
          issuer: "School Games Federation of India",
          date: "Jan 2017",
          description: "Won Gold medals for Jubilee Hills Public School at District level and State Level and represented the school at National Level."
        }
      ]
    }
  ];

  return (
    <section id="education" className={`education-section ${darkMode ? 'dark' : ''}`}>
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <FaGraduationCap /> Education
      </motion.h2>
      <div className="education-timeline">
        {educations.map((edu, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-icon">
                <FaGraduationCap />
              </div>
              <EducationCard {...edu} darkMode={darkMode} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Education;