import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaArrowDown, FaMoon, FaSun } from 'react-icons/fa';
import './landing.css';
import Navbar from './navbar';

const CodeTerminal = () => {
  const [text, setText] = useState('');
  const fullText = `
<span class="comment">// Welcome to Varun's Code Terminal</span>
<span class="keyword">function</span> <span class="function">createInnovation</span>(skills, passion) {
  <span class="keyword">return</span> skills.<span class="function">map</span>(skill => {
    <span class="keyword">return</span> skill * passion;
  }).<span class="function">reduce</span>((a, b) => a + b);
}

<span class="keyword">let</span> skills = [<span class="string">'Java'</span>, <span class="string">'JavaScript'</span>, <span class="string">'React'</span>, <span class="string">'Node.js'</span>, <span class="string">'Python'</span>];
<span class="keyword">let</span> passion = <span class="number">Infinity</span>;

console.<span class="function">log</span>(createInnovation(skills, passion));
<span class="comment">// Output: Limitless Potential</span>
  `.trim();

  useEffect(() => {
    let i = 0;
    const typing = setInterval(() => {
      setText(fullText.slice(0, i));
      i++;
      if (i > fullText.length) {
        clearInterval(typing);
      }
    }, 50);

    return () => clearInterval(typing);
  }, []);

  return (
    <div className="code-terminal">
      <div className="terminal-header">
        <div className="window-controls">
          <div className="window-control close"></div>
          <div className="window-control minimize"></div>
          <div className="window-control fullscreen"></div>
        </div>
      </div>
      <pre className="terminal-body">
        <code dangerouslySetInnerHTML={{ __html: text }}></code>
      </pre>
    </div>
  );
};

const Landing = () => {
  const [darkMode, setDarkMode] = useState(false);
  const backgroundWords = [
    "PROGRAMMER","TENNIS PLAYER", "INNOVATOR", "DEVELOPER", "ENGINEER"
  ];

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`landing-container ${darkMode ? 'dark' : ''}`}>
      <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode}/>
      <div className="background-text-container">
        <div className="background-text">
          {backgroundWords.concat(backgroundWords).map((word, index) => (
            <span key={index} className="background-word">{word}</span>
          ))}
        </div>
      </div>
      
      <div className="content">
        <motion.div 
          className="header"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2>HI, I'M</h2>
          <h1>VARUN GOLUSUPUDI</h1>
          <p className="subtitle">Turning Ideas into Code | UCSC CS Major</p>
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <CodeTerminal />
        </motion.div>
        
        <motion.div
            className="footer"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
        >
            <button 
            className="dark-mode-toggle"
            onClick={() => setDarkMode(!darkMode)}
            aria-label="Toggle dark mode"
            >
            {darkMode ? <FaSun /> : <FaMoon />}
            </button>
            <div className="learn-more">
            <h3>Learn more about me</h3>
            </div>
            <div className="scroll-arrow">
            <FaArrowDown />
            </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Landing;