import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBriefcase, FaCalendar, FaMapMarkerAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './Workexperience.css';

const ExperienceCard = ({ company, role, duration, location, description, skills, darkMode, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div 
      className={`experience-card ${darkMode ? 'dark' : ''}`}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <div className="card-header" onClick={() => setIsExpanded(!isExpanded)}>
        <h3>{company}</h3>
        <motion.div 
          className="expand-icon"
          animate={{ rotate: isExpanded ? 180 : 0 }}
        >
          {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </motion.div>
      </div>
      <h4>{role}</h4>
      <p className="duration-location">
        <FaCalendar /> {duration} <FaMapMarkerAlt /> {location}
      </p>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className="description">{description}</p>
            <div className="skills">
              {skills.map((skill, index) => (
                <motion.span 
                  key={index} 
                  className="skill"
                  whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
                >
                  {skill}
                </motion.span>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const WorkExperience = ({ darkMode }) => {
  const experiences = [
    {
      company: "Salesforce",
      role: "Software Engineer Intern",
      duration: "Jun 2024 - Sep 2024 · 3 mos",
      location: "San Francisco, California, United States",
      description: "Internship at Salesforce, contributing to cutting-edge software solutions.",
      skills: ["React", "JavaScript", "Node.js"]
    },
    {
      company: "Baskin Engineering at UCSC",
      role: "Group Tutor CSE 13S",
      duration: "Jan 2023 - Jun 2024 · 1 yr 6 mos",
      location: "Santa Cruz, California, United States",
      description: "Group Tutor for CSE 13S (C Programming) for a class of 240 students. Teach fundamental C programming concepts and debugging techniques.",
      skills: ["C (Programming Language)", "Teaching", "Debugging"]
    },
    {
      company: "Tech4Good @ UCSC",
      role: "Frontend Developer",
      duration: "Sep 2022 - Jun 2024 · 1 yr 10 mos",
      location: "Santa Cruz, California, United States",
      description: "Working on the UI components of Dynamic Surveys, a real-time application that provides qualitative and quantitative data through clusters built on responses to open-ended questions.",
      skills: ["HTML", "CSS", "JavaScript", "React"]
    },
    {
      company: "Zenith Services Inc.",
      role: "Software Engineer Intern",
      duration: "Jun 2023 - Sep 2023 · 3 mos",
      location: "Princeton, New Jersey, United States · Hybrid",
      description: "Developed dynamic and user-friendly screens for Lorry Circle, a mobile application, using Flutter.",
      skills: ["Flutter", "Java", "Mobile Development"]
    }
  ];

  return (
    <section id="experience" className={`experience-section ${darkMode ? 'dark' : ''}`}>
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <FaBriefcase /> Work Experience
      </motion.h2>
      <div className="cute-timeline">
        {experiences.map((exp, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-icon">
                <FaBriefcase />
              </div>
              <ExperienceCard {...exp} darkMode={darkMode} index={index} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WorkExperience;