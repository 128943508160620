import React from 'react';
import { motion } from 'framer-motion';
import './About.css';

const AboutMe = ({ darkMode }) => {
  return (
    <section id="about" className={`about-section ${darkMode ? 'dark' : ''}`}>
      <motion.div 
        className="about-content"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2>About Me</h2>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.3 }}
        >
          I'm a rising senior at UCSC majoring in Computer Science. As an international student from India, I bring a unique perspective and diverse experiences to my studies and projects.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.5 }}
        >
          Before diving into the world of coding, I used to play professional tennis. This experience taught me discipline, perseverance, and the importance of strategic thinking.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.7 }}
        >
          I'm passionate about creating impactful solutions that push the boundaries of what's possible with technology. My journey in tech is driven by a desire to innovate and collaborate on projects that make a difference.
        </motion.p>
        <motion.div 
          className="skills-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.9 }}
        >
          <h3>Skills</h3>
          <div className="skills-list">
            {['Java', 'JavaScript', 'React', 'Node.js', 'Python', 'C'].map((skill, index) => (
              <motion.div 
                className="skill-item" 
                key={index}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                {skill}
              </motion.div>
            ))}
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default AboutMe;
